import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';

const Breadcrumbs: React.FC = () => {
  return (
    <nav className="flex items-center space-x-2 text-sm mb-4" aria-label="Breadcrumb">
      <Link to="/" className="text-gray-500 hover:text-gray-700 flex items-center">
        <Home className="w-4 h-4 mr-1" />
        Home
      </Link>
      <span className="text-gray-400">/</span>
      <span className="text-gray-900">Search</span>
    </nav>
  );
};

export default Breadcrumbs;
