/* src\utils\lessonParser.ts */

import { marked } from 'marked';

interface LessonMeta {
  title: string;
  category: string;
  order: number;
  description?: string;
}

export interface ParsedLesson {
  meta: LessonMeta;
  content: string;
}

class LessonParser {
  // Reintroduce \r? to handle CRLF line endings
  private static readonly FRONTMATTER_REGEX = /^---\r?\n([\s\S]*?)\r?\n---\r?\n([\s\S]*)$/;
  private static readonly EXAMPLE_REGEX = /:::\s*example\s*({[^}]+})?\r?\n([\s\S]*?)\r?\n:::/g;
  private static readonly NOTE_REGEX = /:::\s*note\s*({[^}]+})?\r?\n([\s\S]*?)\r?\n:::/g;
  private static readonly SCENARIO_REGEX = /:::\s*scenario\s*(\{[^\}]+\})?\r?\n([\s\S]*?)\r?\n:::/g;
  private static readonly TRANSLIST_REGEX = /:::\s*translist\s*(\{[^\}]+\})?\r?\n([\s\S]*?)\r?\n:::/g;

  static async parse(markdown: string): Promise<ParsedLesson> {

    const { meta, content } = this.parseFrontmatter(markdown);

    if (!meta.title || !meta.category || typeof meta.order !== 'number') {
      throw new Error('Invalid frontmatter: Missing required fields');
    }

    marked.setOptions({
      gfm: true,
      breaks: true,
    });

    let processedMarkdown = this.processExampleBlocks(content);
    processedMarkdown = this.processNoteBlocks(processedMarkdown);
    processedMarkdown = this.processScenarioBlocks(processedMarkdown);
    processedMarkdown = this.processTransListBlocks(processedMarkdown);

    let html = await marked(processedMarkdown);
    html = this.processPronunciationGuides(html);

    return { meta, content: `<div class="lesson-content">${html}</div>` };
  }

  private static parseFrontmatter(markdown: string): { meta: LessonMeta; content: string } {
    const match = markdown.match(this.FRONTMATTER_REGEX);
    if (!match) {
      throw new Error('Invalid markdown format: Missing frontmatter');
    }

    const [, frontmatterStr, bodyContent] = match;
    const meta: Partial<LessonMeta> = {};
    const lines = frontmatterStr.split(/\r?\n/);

    for (const line of lines) {
      const [key, ...valueParts] = line.split(':').map(part => part.trim());
      if (key && valueParts.length) {
        const value = valueParts.join(':').replace(/^["'](.*)["']$/, '$1');
        switch (key) {
          case 'title':
            meta.title = value;
            break;
          case 'category':
            meta.category = value;
            break;
          case 'order':
            meta.order = parseInt(value, 10);
            break;
          case 'description':
            meta.description = value;
            break;
        }
      }
    }

    return { meta: meta as LessonMeta, content: bodyContent.trim() };
  }

  private static processExampleBlocks(markdown: string): string {
    return markdown.replace(this.EXAMPLE_REGEX, (match, config, body) => {
      let label = '';
      if (config) {
        try {
          const parsedConfig = JSON.parse(config);
          label = parsedConfig.label || '';
        } catch (error) {
          console.error('Error parsing example config:', error);
        }
      }

      const lines = body.trim().split('\n');
      const spanishLine = lines[0] || '';
      const englishLine = lines[1] || '';

      return `<div class="example-box" data-label="${label}">
                <div class="spanish-line">${spanishLine}</div>
                ${englishLine ? `<div class="english-line">${englishLine}</div>` : ''}
              </div>`;
    });
  }

  private static processNoteBlocks(markdown: string): string {
    return markdown.replace(this.NOTE_REGEX, (match, config, body) => {
      let label = '';
      if (config) {
        try {
          const parsedConfig = JSON.parse(config);
          label = parsedConfig.label || '';
        } catch (error) {
          console.error('Error parsing note config:', error);
        }
      }

      const content = body.trim();
      return `<div class="note-box" data-label="${label}" data-content="${content}"></div>`;
    });
  }

  private static processScenarioBlocks(markdown: string): string {
    return markdown.replace(this.SCENARIO_REGEX, (match, config, body) => {
      let title = 'Scenario';
      let audioPath = '';
      if (config) {
        try {
          const parsedConfig = JSON.parse(config);
          title = parsedConfig.title || 'Scenario';
          audioPath = parsedConfig.audioPath || '';
        } catch (error) {
          console.error('Error parsing scenario config:', error);
        }
      }

      const lines = body.trim().split('\n').filter((l: string) => l.trim() !== '');
      const lineData = lines.map((line: string, index: number) => {
        const speakerMatch = line.match(/\*\*(.*?)\*\*:\s*(.*)/);
        if (!speakerMatch) return null;

        const speaker = speakerMatch[1].trim();
        let remaining = speakerMatch[2].trim();

        let english = '';
        const englishMatch = remaining.match(/\(([^)]+)\)\s*$/);
        if (englishMatch) {
          english = englishMatch[1].trim();
          remaining = remaining.replace(/\(.*?\)\s*$/, '').trim();
        }

        const spanish = remaining;

        return {
          speaker,
          spanish,
          english,
          lineNumber: index + 1
        };
      }).filter(Boolean);

      const scenarioData = JSON.stringify({ title, audioPath, lines: lineData });
      const safeScenarioData = scenarioData.replace(/'/g, '&#39;');

      return `<div class="scenario-box" data-scenario='${safeScenarioData}'></div>`;
    });
  }

  private static processTransListBlocks(markdown: string): string {
    return markdown.replace(this.TRANSLIST_REGEX, (match, config, body) => {
      let label = '';
      if (config) {
        try {
          const parsedConfig = JSON.parse(config);
          label = parsedConfig.label || '';
        } catch (error) {
          console.error('Error parsing translist config:', error);
        }
      }

      const lines = body
        .trim()
        .split('\n')
        .map((line: string) => line.trim())
        .filter(Boolean);

      const items = [];
      for (let i = 0; i < lines.length; i += 2) {
        const spanish = lines[i] || '';
        const englishLine = lines[i + 1] || '';
        const english = englishLine.replace(/^-+\s*/, '');
        items.push({ spanish, english });
      }

      const itemsHtml = items.map(item => {
        return `
          <div class="translist-item">
            <div class="spanish-line">${item.spanish}</div>
            <div class="english-line">- ${item.english}</div>
          </div>
        `;
      }).join('');

      return `
        <div class="translist-box" data-label="${label}">
          ${itemsHtml}
        </div>
      `;
    });
  }

  private static processPronunciationGuides(html: string): string {
    const pronRegex = /<em>([^<]+)<\/em>\s*\(([^)]+)\)/g;
    return html.replace(pronRegex, (_, word, pronunciation) => {
      return `<pronunciation-guide data-word="${word.trim()}" data-pronunciation="${pronunciation.trim()}"><em>${word}</em></pronunciation-guide>`;
    });
  }
}

export { LessonParser };
export type { LessonMeta };
