import React from 'react';

interface AccentContextValue {
  accent: 'latam' | 'castilian';
  setAccent: React.Dispatch<React.SetStateAction<'latam' | 'castilian'>>;
}

export const AccentContext = React.createContext<AccentContextValue>({
  accent: 'latam',
  setAccent: () => {}
});
