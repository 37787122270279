import { Category } from '../../types';

export const BEGINNER_TOPICS: Category[] = [
  {
    id: 'alphabet',
    title: 'Spanish Alphabet and Pronunciation',
    lessons: [
      { id: 'alphabet', title: 'The Spanish Alphabet: Letters and Sounds', slug: 'alphabet', category: 'alphabet', content: '', order: 1 },
      { id: 'pronunciation', title: 'Pronunciation of Spanish Vowels and Consonants', slug: 'pronunciation', category: 'alphabet', content: '', order: 2 },
      { id: 'accent-marks', title: 'Understanding Spanish Accent Marks and Stress', slug: 'accent-marks', category: 'alphabet', content: '', order: 3 },
      { id: 'diphthongs', title: 'Spanish Diphthongs and Triphthongs', slug: 'diphthongs', category: 'alphabet', content: '', order: 4 },
      { id: 'pronunciation-tips', title: 'Tips for Improving Spanish Pronunciation', slug: 'pronunciation-tips', category: 'alphabet', content: '', order: 5 }
    ]
  },
  {
    id: 'basic-vocabulary',
    title: 'Basic Spanish Vocabulary',
    lessons: [
      { id: 'greetings', title: 'Greetings and Introductions in Spanish', slug: 'greetings', category: 'basic-vocabulary', content: '', order: 1 },
      { id: 'common-phrases', title: 'Common Spanish Words and Phrases for Beginners', slug: 'common-phrases', category: 'basic-vocabulary', content: '', order: 2 },
      { id: 'numbers', title: 'Numbers in Spanish: Counting from 1 to 1000', slug: 'numbers', category: 'basic-vocabulary', content: '', order: 3 },
      { id: 'calendar', title: 'Days, Months, and Seasons in Spanish', slug: 'calendar', category: 'basic-vocabulary', content: '', order: 4 },
      { id: 'colors-shapes', title: 'Colors and Shapes Vocabulary', slug: 'colors-shapes', category: 'basic-vocabulary', content: '', order: 5 },
      { id: 'family', title: 'Family Members in Spanish', slug: 'family', category: 'basic-vocabulary', content: '', order: 6 },
      { id: 'weather', title: 'Weather and Seasons Vocabulary', slug: 'weather', category: 'basic-vocabulary', content: '', order: 7 },
      { id: 'food-drinks', title: 'Food and Drinks: Essential Spanish Terms', slug: 'food-drinks', category: 'basic-vocabulary', content: '', order: 8 },
      { id: 'travel', title: 'Travel and Transportation Vocabulary', slug: 'travel', category: 'basic-vocabulary', content: '', order: 9 },
      { id: 'emergency', title: 'Emergency Phrases in Spanish', slug: 'emergency', category: 'basic-vocabulary', content: '', order: 10 }
    ]
  },
  {
    id: 'grammar-basics',
    title: 'Spanish Grammar Basics',
    lessons: [
      { id: 'intro-grammar', title: 'Introduction to Spanish Grammar', slug: 'grammar-intro', category: 'grammar-basics', content: '', order: 1 },
      { id: 'noun-gender', title: 'Gender of Nouns: Masculine and Feminine', slug: 'noun-gender', category: 'grammar-basics', content: '', order: 2 },
      { id: 'plurals', title: 'Singular and Plural Forms in Spanish', slug: 'plurals', category: 'grammar-basics', content: '', order: 3 },
      { id: 'articles', title: 'Definite and Indefinite Articles', slug: 'articles', category: 'grammar-basics', content: '', order: 4 },
      { id: 'pronouns', title: 'Subject Pronouns in Spanish', slug: 'pronouns', category: 'grammar-basics', content: '', order: 5 },
      { id: 'sentence-structure', title: 'Basic Sentence Structure', slug: 'sentence-structure', category: 'grammar-basics', content: '', order: 6 },
      { id: 'adjectives', title: 'Using Adjectives Correctly', slug: 'adjectives', category: 'grammar-basics', content: '', order: 7 },
      { id: 'verbs-intro', title: 'Introduction to Spanish Verbs', slug: 'verbs-intro', category: 'grammar-basics', content: '', order: 8 }
    ]
  }
];