import React from 'react';
import TopicSection from './TopicSection';
import { BEGINNER_TOPICS, INTERMEDIATE_TOPICS } from '../../data/topics/index';
import { Category } from '../../types';

const LearningPathSection: React.FC = () => {
  // Explicitly type advancedTopics as Category[]
  const advancedTopics: Category[] = []; // empty now, indicating "coming soon"

  return (
    <div id="learning-path" className="w-full scroll-mt-16" style={{ backgroundColor: '#F9F8FF' }}>
      <div className="py-8 sm:py-12 md:py-16 lg:py-20 px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-3 sm:mb-4 text-slate-900">
            Your Spanish Learning Path
          </h2>
          
          <p className="text-base sm:text-lg text-center text-gray-600 mb-8 sm:mb-12 
                       max-w-2xl mx-auto px-4">
            Follow our structured path from basic phrases to advanced conversation. 
            Each section builds on the previous one, ensuring a solid foundation.
          </p>
          
          <div className="space-y-8 sm:space-y-12 md:space-y-16">
            <TopicSection
              title="Getting Started"
              level="Beginner"
              estimatedTime="4-6 weeks"
              topics={BEGINNER_TOPICS}
              sectionNumber={1}
            />

            <TopicSection
              title="Building Fluency"
              level="Intermediate"
              estimatedTime="8-12 weeks"
              topics={INTERMEDIATE_TOPICS}
              sectionNumber={2}
            />

            <TopicSection
              title="Mastering Spanish"
              level="Advanced"
              estimatedTime="12-16 weeks"
              topics={advancedTopics}
              sectionNumber={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathSection;