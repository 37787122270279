import { Category } from '../../types';

export const INTERMEDIATE_TOPICS: Category[] = [
  {
    id: 'verbs-tenses',
    title: 'Spanish Verbs and Tenses',
    lessons: [
      { id: 'present-tense', title: 'Regular Verbs in the Present Tense', slug: 'spanish-present-tense', category: 'verbs-tenses', content: '', order: 1 },
      { id: 'irregular-verbs', title: 'Irregular Verbs: Ser, Estar, Ir, and More', slug: 'spanish-irregular-verbs', category: 'verbs-tenses', content: '', order: 2 },
      { id: 'present-progressive', title: 'Present Progressive Tense in Spanish', slug: 'spanish-present-progressive', category: 'verbs-tenses', content: '', order: 3 },
      { id: 'past-tenses', title: 'Past Tenses: Preterite vs. Imperfect', slug: 'spanish-past-tenses', category: 'verbs-tenses', content: '', order: 4 },
      { id: 'future-tense', title: 'Future Tense: Expressing Future Actions', slug: 'spanish-future-tense', category: 'verbs-tenses', content: '', order: 5 },
      { id: 'conditional', title: 'Conditional Tense: Expressing Possibilities', slug: 'spanish-conditional', category: 'verbs-tenses', content: '', order: 6 },
      { id: 'subjunctive', title: 'The Subjunctive Mood: An Introduction', slug: 'spanish-subjunctive', category: 'verbs-tenses', content: '', order: 7 },
      { id: 'reflexive-verbs', title: 'Using Reflexive Verbs in Spanish', slug: 'spanish-reflexive-verbs', category: 'verbs-tenses', content: '', order: 8 },
      { id: 'imperative', title: 'Imperative Mood: Giving Commands', slug: 'spanish-imperative', category: 'verbs-tenses', content: '', order: 9 }
    ]
  },
  {
    id: 'nouns-articles',
    title: 'Nouns and Articles',
    lessons: [
      { id: 'gender-rules', title: 'Understanding Gender in Spanish Nouns', slug: 'spanish-gender-rules', category: 'nouns-articles', content: '', order: 1 },
      { id: 'definite-articles', title: 'Using Definite Articles: El, La, Los, Las', slug: 'spanish-definite-articles', category: 'nouns-articles', content: '', order: 2 },
      { id: 'indefinite-articles', title: 'Using Indefinite Articles: Un, Una, Unos, Unas', slug: 'spanish-indefinite-articles', category: 'nouns-articles', content: '', order: 3 },
      { id: 'gender-exceptions', title: 'Exceptions to Gender Rules', slug: 'spanish-gender-exceptions', category: 'nouns-articles', content: '', order: 4 },
      { id: 'plural-rules', title: 'Pluralization Rules in Spanish', slug: 'spanish-plural-rules', category: 'nouns-articles', content: '', order: 5 }
    ]
  },
  {
    id: 'adjectives-adverbs',
    title: 'Adjectives and Adverbs',
    lessons: [
      { id: 'adjective-placement', title: 'Placement of Adjectives in Sentences', slug: 'spanish-adjective-placement', category: 'adjectives-adverbs', content: '', order: 1 },
      { id: 'adjective-agreement', title: 'Agreement of Adjectives in Gender and Number', slug: 'spanish-adjective-agreement', category: 'adjectives-adverbs', content: '', order: 2 },
      { id: 'comparatives', title: 'Comparative and Superlative Forms', slug: 'spanish-comparatives', category: 'adjectives-adverbs', content: '', order: 3 },
      { id: 'common-adverbs', title: 'Common Spanish Adverbs', slug: 'spanish-common-adverbs', category: 'adjectives-adverbs', content: '', order: 4 },
      { id: 'forming-adverbs', title: 'Forming Adverbs from Adjectives', slug: 'spanish-forming-adverbs', category: 'adjectives-adverbs', content: '', order: 5 }
    ]
  },
  {
    id: 'prepositions-conjunctions',
    title: 'Prepositions and Conjunctions',
    lessons: [
      { id: 'common-prepositions', title: 'Common Spanish Prepositions', slug: 'spanish-common-prepositions', category: 'prepositions-conjunctions', content: '', order: 1 },
      { id: 'por-para', title: 'Using "Por" vs. "Para"', slug: 'spanish-por-para', category: 'prepositions-conjunctions', content: '', order: 2 },
      { id: 'place-time', title: 'Prepositions of Place and Time', slug: 'spanish-place-time-prepositions', category: 'prepositions-conjunctions', content: '', order: 3 },
      { id: 'conjunctions', title: 'Spanish Conjunctions: Connecting Ideas', slug: 'spanish-conjunctions', category: 'prepositions-conjunctions', content: '', order: 4 },
      { id: 'prepositional-phrases', title: 'Prepositional Phrases in Spanish', slug: 'spanish-prepositional-phrases', category: 'prepositions-conjunctions', content: '', order: 5 }
    ]
  }
];