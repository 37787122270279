import React from 'react';

interface DifficultyBadgeProps {
  level: string;
}

const DifficultyBadge: React.FC<DifficultyBadgeProps> = ({ level }) => {
  // Using violet for all badges with different opacities
  const colors = {
    'Beginner': 'bg-violet-100 text-violet-800',
    'Intermediate': 'bg-violet-200 text-violet-800',
    'Advanced': 'bg-violet-300 text-violet-800'
  }[level];

  return (
    <span className={`${colors} text-xs font-medium px-2.5 py-0.5 rounded-full`}>
      {level}
    </span>
  );
};

export default DifficultyBadge;