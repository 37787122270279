import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Users, GraduationCap, Globe } from 'lucide-react';

const About: React.FC = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "LearnSpanish.org",
    "description": "Free online Spanish learning platform with comprehensive lessons from beginner to advanced levels.",
    "url": "https://learnspanish.org/about",
    "sameAs": [
      "https://twitter.com/learnspanishorg",
      "https://facebook.com/learnspanishorg"
    ]
  };

  return (
    <>
      <Helmet>
        <title>About Us - Learn Spanish Online Free | LearnSpanish.org</title>
        <meta 
          name="description" 
          content="Learn about LearnSpanish.org's mission to make Spanish language learning accessible, enjoyable, and effective for everyone through free online lessons." 
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main className="min-h-screen bg-gray-50">
        {/* Hero Section */}
        <div className="bg-violet-600 text-white">
          <div className="max-w-4xl mx-auto px-4 py-16 text-center">
            <h1 className="text-4xl font-bold mb-6">About LearnSpanish.org</h1>
            <p className="text-xl text-violet-100">
              Your gateway to mastering the Spanish language and embracing a vibrant global culture.
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-4xl mx-auto px-4 py-12">
          {/* Mission Section */}
          <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-bold mb-4">Our Mission</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
              Our mission is simple: to make learning Spanish accessible, enjoyable, and effective for everyone, 
              whether you're starting as a beginner or aiming to refine your advanced skills.
            </p>
          </div>

          {/* Who We Are Section */}
          <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
            <div className="flex items-start gap-4">
              <Users className="w-8 h-8 text-violet-600 flex-shrink-0 mt-1" />
              <div>
                <h2 className="text-2xl font-bold mb-4">Who We Are</h2>
                <p className="text-gray-600 leading-relaxed">
                  LearnSpanish.org is a free, comprehensive learning platform designed for self-paced 
                  language learners. We're passionate about empowering individuals to communicate confidently 
                  in Spanish while discovering the rich history, traditions, and diversity of Spanish-speaking 
                  communities worldwide.
                </p>
              </div>
            </div>
          </div>

          {/* What We Offer Section */}
          <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-bold mb-6">What We Offer</h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <GraduationCap className="w-6 h-6 text-violet-600 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-semibold mb-2">Step-by-Step Lessons</h3>
                    <p className="text-gray-600">From the Spanish alphabet to advanced grammar, we provide structured lessons that build on one another.</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <Globe className="w-6 h-6 text-violet-600 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-semibold mb-2">Real-World Scenarios</h3>
                    <p className="text-gray-600">Learn practical phrases and vocabulary you'll use in everyday conversations.</p>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <svg className="w-6 h-6 text-violet-600 flex-shrink-0 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                  </svg>
                  <div>
                    <h3 className="font-semibold mb-2">Pronunciation Guides</h3>
                    <p className="text-gray-600">Master Spanish sounds with detailed tips for both Latin American and Castilian accents.</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <svg className="w-6 h-6 text-violet-600 flex-shrink-0 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                  <div>
                    <h3 className="font-semibold mb-2">Cultural Insights</h3>
                    <p className="text-gray-600">Discover the traditions, customs, and nuances that make Spanish-speaking cultures unique.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Why Learn With Us Section */}
          <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-bold mb-6">Why Learn With Us?</h2>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="text-center p-4 bg-violet-50 rounded-lg">
                <h3 className="font-semibold mb-2">Free Forever</h3>
                <p className="text-gray-600 text-sm">No hidden costs or subscriptions required.</p>
              </div>
              <div className="text-center p-4 bg-violet-50 rounded-lg">
                <h3 className="font-semibold mb-2">Practical Focus</h3>
                <p className="text-gray-600 text-sm">Learn skills you'll actually use in real life.</p>
              </div>
              <div className="text-center p-4 bg-violet-50 rounded-lg">
                <h3 className="font-semibold mb-2">Self-Paced</h3>
                <p className="text-gray-600 text-sm">Learn at your own pace and track your progress.</p>
              </div>
            </div>
          </div>

          {/* Vision Section */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
            <p className="text-gray-600 leading-relaxed">
              At LearnSpanish.org, we believe that learning a language opens doors to new opportunities, 
              friendships, and perspectives. By fostering a love for the Spanish language, we aim to 
              connect learners from around the globe with a shared passion for communication and 
              cultural exchange.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default About;