import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GraduationCap, Volume2, BookOpen } from 'lucide-react';
import Hero from '../components/home/Hero';
import LearningPathSection from '../components/home/LearningPathSection';
import WhyLearnWithUs from '../components/home/WhyLearnWithUs';
import { AdSense } from '../components/ads';

const Home: React.FC = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Learn Spanish Online",
    "description": "Comprehensive Spanish language learning program with structured lessons from beginner to advanced levels. Features pronunciation guides, grammar lessons, and practical exercises.",
    "provider": {
      "@type": "Organization",
      "name": "LearnSpanish.org",
      "sameAs": "https://learnspanish.org"
    },
    "educationalLevel": "Beginner to Advanced",
    "inLanguage": "English, Spanish",
    "isAccessibleForFree": true
  };

  return (
    <>
      <Helmet>
        <title>Learn Spanish Online Free - Complete Spanish Course | LearnSpanish.org</title>
        <meta 
          name="description" 
          content="Master Spanish with our free, comprehensive online course. Features step-by-step lessons, pronunciation guides, and practical exercises for all levels." 
        />
        <meta 
          name="keywords" 
          content="learn spanish, spanish course, free spanish lessons, spanish grammar, spanish pronunciation" 
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main>
        <Hero />
        
        <section aria-label="Course Benefits" className="py-16 bg-white">
          <div className="max-w-4xl mx-auto px-4">
            <h2 className="sr-only">Why Learn Spanish With Us</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {/* Structured Learning Path */}
              <div className="group p-6 bg-violet-50 rounded-2xl transition-all hover:bg-violet-100 hover:shadow-md">
                <div className="mb-4 inline-block p-3 bg-violet-100 rounded-xl group-hover:bg-violet-200 transition-all">
                  <GraduationCap className="w-6 h-6 text-violet-600" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Structured Learning Path</h3>
                <p className="text-gray-600 leading-relaxed">
                  Progress from beginner to advanced with our carefully designed curriculum that builds your skills step by step.
                </p>
              </div>

              {/* Clear Pronunciation with placeholder message */}
              <div className="group p-6 bg-violet-50 rounded-2xl transition-all hover:bg-violet-100 hover:shadow-md relative">
                <div className="mb-4 inline-block p-3 bg-violet-100 rounded-xl group-hover:bg-violet-200 transition-all">
                  <Volume2 className="w-6 h-6 text-violet-600" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Clear Pronunciation</h3>
                <p className="text-gray-600 leading-relaxed mb-2">
                  Master Spanish sounds with our detailed pronunciation guides.
                </p>
                <p className="text-sm text-gray-500 italic">
                  Audio files are being prepared. Check back soon!
                </p>
              </div>

              {/* Practical Examples */}
              <div className="group p-6 bg-violet-50 rounded-2xl transition-all hover:bg-violet-100 hover:shadow-md">
                <div className="mb-4 inline-block p-3 bg-violet-100 rounded-xl group-hover:bg-violet-200 transition-all">
                  <BookOpen className="w-6 h-6 text-violet-600" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Practical Examples</h3>
                <p className="text-gray-600 leading-relaxed">
                  Learn with real-world scenarios and everyday conversations you'll actually use.
                </p>
              </div>
            </div>
          </div>
        </section>

        <LearningPathSection />
        <WhyLearnWithUs />

        {/* AdSense placement at the bottom of the page */}
        {/* <AdSense position="home-bottom" /> */}
      </main>
    </>
  );
};

export default Home;