import React, { createContext, useContext, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { BEGINNER_TOPICS, INTERMEDIATE_TOPICS, ADVANCED_TOPICS } from '../data/topics';
import { Lesson } from '../types';

interface SearchableItem {
  type: 'lesson';
  title: string;
  description?: string;
  slug: string;
  category: string;
  content: string;
  hasContent: boolean;
}

interface SearchContextType {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  results: SearchableItem[];
  isSearching: boolean;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  performSearch: (term: string) => void;
  loadFromStorage: (term: string) => boolean;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const fuseOptions = {
  includeScore: true,
  threshold: 0.3,
  keys: [
    { name: 'title', weight: 0.4 },
    { name: 'description', weight: 0.3 },
    { name: 'content', weight: 0.2 },
    { name: 'category', weight: 0.1 }
  ]
};

const hasLessonContent = (lesson: Lesson): boolean => {
  return (
    lesson.category.startsWith('alphabet') ||
    lesson.category.startsWith('basic-vocabulary') ||
    lesson.category.startsWith('grammar-basics') ||
    lesson.category.startsWith('verbs-tenses') ||
    lesson.category.startsWith('nouns-articles') ||
    lesson.category.startsWith('adjectives-adverbs') ||
    lesson.category.startsWith('prepositions-conjunctions')
  );
};

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<SearchableItem[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [fuse, setFuse] = useState<Fuse<SearchableItem> | null>(null);
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    const allTopics = [...BEGINNER_TOPICS, ...INTERMEDIATE_TOPICS];
    const searchableItems: SearchableItem[] = allTopics.flatMap(category =>
      category.lessons
        .filter(hasLessonContent)
        .map(lesson => ({
          type: 'lesson',
          title: lesson.title,
          description: '',
          slug: lesson.slug,
          category: category.title,
          content: lesson.content,
          hasContent: true
        }))
    );

    ADVANCED_TOPICS.forEach(category => {
      category.lessons.forEach(lesson => {
        searchableItems.push({
          type: 'lesson',
          title: lesson.title,
          description: 'Coming soon...',
          slug: lesson.slug,
          category: category.title,
          content: '',
          hasContent: false
        });
      });
    });

    setFuse(new Fuse(searchableItems, fuseOptions));
  }, []);

  const performSearch = (term: string) => {
    if (!fuse || term.trim() === '') {
      setResults([]);
      setTotalPages(1);
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    const searchResults = fuse.search(term);

    const items = searchResults
      .map(result => result.item)
      .sort((a, b) => {
        if (a.hasContent && !b.hasContent) return -1;
        if (!a.hasContent && b.hasContent) return 1;
        return 0;
      });

    setResults(items);
    setTotalPages(Math.ceil(items.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
    setIsSearching(false);

    // Store searchTerm and results in localStorage
    localStorage.setItem('lastSearchTerm', term);
    localStorage.setItem('lastSearchResults', JSON.stringify(items));
  };

  const loadFromStorage = (term: string): boolean => {
    const storedTerm = localStorage.getItem('lastSearchTerm');
    const storedResults = localStorage.getItem('lastSearchResults');
    if (storedTerm && storedResults && storedTerm === term) {
      const parsedResults: SearchableItem[] = JSON.parse(storedResults);
      setResults(parsedResults);
      setTotalPages(Math.ceil(parsedResults.length / ITEMS_PER_PAGE));
      setCurrentPage(1);
      return true;
    }
    return false;
  };

  const paginatedResults = results.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const value = {
    searchTerm,
    setSearchTerm,
    results: paginatedResults,
    isSearching,
    currentPage,
    setCurrentPage,
    totalPages,
    performSearch,
    loadFromStorage
  };

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
