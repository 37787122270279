import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDown, Search } from 'lucide-react';
import { useSearch } from '../../contexts/SearchContext';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const { setSearchTerm, performSearch } = useSearch();
  const [localSearchTerm, setLocalSearchTerm] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const trimmedTerm = localSearchTerm.trim();
    if (!trimmedTerm) return;

    setSearchTerm(trimmedTerm);
    performSearch(trimmedTerm);
    navigate(`/search?q=${encodeURIComponent(trimmedTerm)}`);
  };

  const scrollToLearningPath = () => {
    const element = document.getElementById('learning-path');
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="w-full bg-gradient-to-b from-violet-50/80 to-white">
      <div className="px-4 py-8 sm:py-16 md:py-20">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight mb-4 sm:mb-6 
                       bg-gradient-to-r from-gray-900 to-gray-600 
                       bg-clip-text text-transparent">
            Learn Spanish. Free Forever.
          </h1>
          
          <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-6 sm:mb-8 px-4">
            Start your Spanish journey with our structured, step-by-step learning path
          </p>
          
          <form onSubmit={handleSubmit} className="max-w-2xl mx-auto mb-6 sm:mb-8 px-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for lessons, topics, or phrases"
                value={localSearchTerm}
                onChange={(e) => setLocalSearchTerm(e.target.value)}
                className="w-full pl-4 pr-12 py-3 sm:py-4 rounded-xl border-2 border-violet-100 
                         bg-white/80 backdrop-blur-sm
                         focus:border-violet-300 focus:ring-2 focus:ring-violet-200 
                         focus:outline-none transition-all
                         placeholder:text-gray-400 text-gray-900
                         text-base sm:text-lg"
                aria-label="Search Spanish lessons"
              />
              <button
                type="submit"
                className="absolute right-3 sm:right-4 top-1/2 transform -translate-y-1/2 
                         text-violet-600 hover:text-violet-700 transition-colors
                         focus:outline-none focus:ring-2 focus:ring-violet-500
                         disabled:opacity-50 p-2 touch-manipulation"
                disabled={!localSearchTerm.trim()}
                aria-label="Search"
              >
                <Search className="w-5 h-5" />
              </button>
            </div>
          </form>

          <button
            onClick={scrollToLearningPath}
            className="inline-flex items-center gap-2 px-6 py-3 
                     bg-gradient-to-r from-violet-600 to-[rgb(79,69,228)] text-white rounded-xl 
                     hover:opacity-90 active:opacity-95
                     shadow-sm hover:shadow transition-all
                     focus:outline-none focus:ring-2 focus:ring-violet-500/20
                     group touch-manipulation text-base sm:text-lg"
          >
            Get Started
            <ArrowDown 
              className="w-4 h-4 transition-transform group-hover:translate-y-1" 
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;