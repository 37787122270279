import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from '../../types';
import { ChevronRight, Home, ChevronLeft } from 'lucide-react';

interface LessonBreadcrumbsProps {
  category: Category | null;
  title: string;
}

const LessonBreadcrumbs: React.FC<LessonBreadcrumbsProps> = ({ category, title }) => {
  return (
    <>
      {/* Mobile view */}
      <nav 
        aria-label="Breadcrumb"
        className="flex md:hidden items-center text-sm mb-6"
      >
        <Link 
          to="/" 
          className="inline-flex items-center gap-1 text-violet-600 
                     hover:text-violet-700 min-h-[44px]"
        >
          <ChevronLeft className="w-4 h-4" />
          <span>Back to lessons</span>
        </Link>
      </nav>

      {/* Desktop view */}
      <nav 
        aria-label="Breadcrumb"
        className="hidden md:flex items-center gap-2 text-sm mb-6"
      >
        <Link 
          to="/" 
          className="inline-flex items-center text-violet-600 
                     hover:text-violet-700 transition-colors"
        >
          <Home className="w-4 h-4" />
          <span className="sr-only">Home</span>
        </Link>

        <ChevronRight className="w-4 h-4 text-gray-400 flex-shrink-0" />

        {category && (
          <>
            <span className="text-gray-600">
              {category.title}
            </span>
            <ChevronRight className="w-4 h-4 text-gray-400 flex-shrink-0" />
          </>
        )}

        <span className="text-gray-900 font-medium">
          {title}
        </span>
      </nav>
    </>
  );
};

export default LessonBreadcrumbs;