import React from 'react';
import ResultItem from './ResultItem';

interface ResultsListProps {
  results: {
    title: string;
    description?: string;
    slug: string;
    category: string;
    hasContent: boolean;
  }[];
  selectedResult: number;
  setSelectedResult: React.Dispatch<React.SetStateAction<number>>;
  totalResults: number;
  searchTerm: string;
}

const ResultsList: React.FC<ResultsListProps> = ({
  results,
  selectedResult,
  setSelectedResult,
  totalResults,
  searchTerm
}) => {
  return (
    <div className="space-y-6">
      <p className="text-gray-600">
        Found {totalResults} {totalResults === 1 ? 'result' : 'results'}
      </p>

      <div className="divide-y divide-gray-100">
        {results.map((result, index) => (
          <ResultItem
            key={`${result.slug}-${index}`}
            result={result}
            isSelected={selectedResult === index}
            index={index}
            onMouseEnter={(i) => setSelectedResult(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default ResultsList;
