/* src\components\common\ExampleBox.tsx */

import React, { ReactNode } from 'react';

interface ExampleBoxProps {
  label?: string;
  spanishContent?: ReactNode;
  englishContent?: ReactNode;
}

const ExampleBox: React.FC<ExampleBoxProps> = ({
  label,
  spanishContent,
  englishContent,
}) => {
  if (!spanishContent && !englishContent) {
    return null;
  }

  return (
    <div className="my-4 bg-violet-50 border border-violet-100 rounded-lg shadow-sm max-w-lg pl-6 pr-4 py-4">
      {label && (
        <div className="text-sm text-gray-700 font-semibold mb-3">
          {label}
        </div>
      )}
      {spanishContent && (
        <div className="text-lg text-violet-900 font-medium mb-2">
          {spanishContent}
        </div>
      )}
      {englishContent && (
        <div className="text-gray-600 text-base">
          {englishContent}
        </div>
      )}
    </div>
  );
};

export default ExampleBox;
