import React, { useState } from 'react';
import ScenarioBox from './ScenarioBox';

interface ScenarioLineData {
  speaker: string;
  spanish: string;
  english: string;
  lineNumber: number;
}

interface ScenarioData {
  title: string;
  audioPath: string;
  lines: ScenarioLineData[];
}

interface ScenarioCarouselProps {
  scenarios: ScenarioData[];
  accent: 'latam' | 'castilian';
}

const ScenarioCarousel: React.FC<ScenarioCarouselProps> = ({ scenarios, accent }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const hasMultiple = scenarios.length > 1;

  const goPrev = () => {
    if (!hasMultiple) return;
    setCurrentIndex((prev) => (prev === 0 ? scenarios.length - 1 : prev - 1));
  };

  const goNext = () => {
    if (!hasMultiple) return;
    setCurrentIndex((prev) => (prev === scenarios.length - 1 ? 0 : prev + 1));
  };

  const key = `scenario-${currentIndex}`;

  return (
    <div className="relative flex items-center justify-center">
      {hasMultiple && (
        <>
          <button
            onClick={goPrev}
            aria-label="Previous scenario"
            className="absolute left-0 top-1/2 transform -translate-y-1/2 
                       bg-white hover:bg-gray-50 border border-gray-300 
                       rounded-full p-2 shadow-sm transition-colors 
                       focus:outline-none"
          >
            <svg className="w-5 h-5 text-gray-700" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={goNext}
            aria-label="Next scenario"
            className="absolute right-0 top-1/2 transform -translate-y-1/2 
                       bg-white hover:bg-gray-50 border border-gray-300 
                       rounded-full p-2 shadow-sm transition-colors 
                       focus:outline-none"
          >
            <svg className="w-5 h-5 text-gray-700" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </>
      )}
      
      <ScenarioBox
        key={key}
        title={scenarios[currentIndex].title}
        audioPath={scenarios[currentIndex].audioPath}
        lines={scenarios[currentIndex].lines}
        accent={accent}
      />
    </div>
  );
};

export default ScenarioCarousel;
