import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from '../../types';
import DifficultyBadge from './DifficultyBadge';
import { Clock } from 'lucide-react';

interface TopicSectionProps {
  title: string;
  level: string;
  estimatedTime: string;
  topics: Category[];
  sectionNumber: number;
}

const TopicSection: React.FC<TopicSectionProps> = ({
  title,
  level,
  estimatedTime,
  topics,
  sectionNumber
}) => {
  // Check if topics array is empty or advanced content is not ready
  if (topics.length === 0) {
    return (
      <div className="mb-8 sm:mb-12">
        <div className="flex items-center justify-between mb-4 sm:mb-6">
          <div className="flex items-center gap-3">
            <span className="text-xl sm:text-2xl font-bold text-violet-600">{sectionNumber}</span>
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900">{title}</h3>
            <DifficultyBadge level={level} />
          </div>
          <div className="text-sm text-gray-500 flex items-center gap-2">
            <Clock className="w-4 h-4" />
            {estimatedTime}
          </div>
        </div>
        <div className="bg-white rounded-lg border border-gray-200 p-6 sm:p-8 text-center shadow-sm">
          <p className="text-gray-600 max-w-lg mx-auto">
            We're creating new lessons covering advanced Spanish grammar, conversation skills, and cultural topics. Check back soon!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-8 sm:mb-12">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 sm:mb-6 gap-2 sm:gap-4">
        <div className="flex items-center gap-3">
          <span className="text-xl sm:text-2xl font-bold text-violet-600">{sectionNumber}</span>
          <h3 className="text-xl sm:text-2xl font-bold text-gray-900">{title}</h3>
          <DifficultyBadge level={level} />
        </div>
        <div className="text-sm text-gray-500 flex items-center gap-2">
          <Clock className="w-4 h-4" />
          {estimatedTime}
        </div>
      </div>

      <div className="space-y-4 sm:space-y-6">
        {topics.map((category: Category, categoryIndex: number) => (
          <div 
            key={category.id} 
            className="relative bg-white rounded-lg shadow-sm border border-gray-200 p-4 sm:p-6 
                     hover:shadow-md transition-shadow"
          >
            <div className="absolute -left-3 top-6 w-6 h-6 bg-violet-600 text-white 
                          rounded-full flex items-center justify-center text-sm font-medium">
              {categoryIndex + 1}
            </div>
            
            <div className="ml-4">
              <h4 className="text-lg sm:text-xl font-semibold mb-4 text-gray-900">
                {category.title}
              </h4>
              
              <div className="space-y-2 sm:space-y-3">
                {category.lessons.map((lesson, lessonIndex) => (
                  <Link
                    key={lesson.id}
                    to={`/lesson/${lesson.slug}`}
                    className="flex items-center min-h-[44px] sm:min-h-[48px] p-2 sm:p-3 
                             rounded-lg hover:bg-violet-50 transition-colors group"
                  >
                    <span className="mr-3 text-sm text-gray-400">
                      {lessonIndex + 1}.
                    </span>
                    <span className="text-gray-700 group-hover:text-violet-600 transition-colors">
                      {lesson.title}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopicSection;