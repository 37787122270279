import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SearchPageMetadataProps {
  searchTerm: string;
  currentPage: number;
}

const SearchPageMetadata: React.FC<SearchPageMetadataProps> = ({ searchTerm, currentPage }) => {
  const generatePageTitle = (): string => {
    if (!searchTerm) return 'Search Spanish Lessons | LearnSpanish.org';
    return `Results for "${searchTerm}" | LearnSpanish.org`;
  };

  const generateMetaDescription = (): string => {
    if (!searchTerm) {
      return 'Search through our comprehensive Spanish lessons, grammar guides, and learning resources.';
    }
    return `Search results for "${searchTerm}" - Find Spanish lessons, grammar guides, and learning resources on LearnSpanish.org`;
  };

  const searchSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://learnspanish.org/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://learnspanish.org/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    }
  };

  return (
    <Helmet>
      <title>{generatePageTitle()}</title>
      <meta name="description" content={generateMetaDescription()} />
      <meta name="robots" content="noindex, follow" />
      <link rel="canonical" href="https://learnspanish.org/search" />
      <script type="application/ld+json">
        {JSON.stringify(searchSchema)}
      </script>
    </Helmet>
  );
};

export default SearchPageMetadata;
