import { Category } from '../../types';

export const ADVANCED_TOPICS: Category[] = [
  {
    id: 'advanced-grammar',
    title: 'Advanced Grammar Topics',
    lessons: [
      { id: 'advanced-subjunctive', title: 'The Subjunctive Mood: Advanced Uses', slug: 'spanish-advanced-subjunctive', category: 'advanced-grammar', content: '', order: 1 },
      { id: 'compound-tenses', title: 'Compound Tenses: Present Perfect, Pluperfect', slug: 'spanish-compound-tenses', category: 'advanced-grammar', content: '', order: 2 },
      { id: 'passive-voice', title: 'Passive Voice in Spanish', slug: 'spanish-passive-voice', category: 'advanced-grammar', content: '', order: 3 },
      { id: 'gerunds-participles', title: 'Using Gerunds and Participles', slug: 'spanish-gerunds-participles', category: 'advanced-grammar', content: '', order: 4 }
    ]
  },
  {
    id: 'dialects-variations',
    title: 'Spanish Dialects and Variations',
    lessons: [
      { id: 'regional-differences', title: 'Differences Between European and Latin American Spanish', slug: 'spanish-regional-differences', category: 'dialects-variations', content: '', order: 1 },
      { id: 'regional-slang', title: 'Regional Slang and Expressions', slug: 'spanish-regional-slang', category: 'dialects-variations', content: '', order: 2 },
      { id: 'pronunciation-variations', title: 'Pronunciation Variations Across Regions', slug: 'spanish-pronunciation-variations', category: 'dialects-variations', content: '', order: 3 },
      { id: 'formal-informal', title: 'Understanding Formal and Informal Speech', slug: 'spanish-formal-informal', category: 'dialects-variations', content: '', order: 4 }
    ]
  },
  {
    id: 'practical-skills',
    title: 'Practical Skills and Resources',
    lessons: [
      { id: 'listening-comprehension', title: 'Improving Listening Comprehension', slug: 'spanish-listening-comprehension', category: 'practical-skills', content: '', order: 1 },
      { id: 'reading-practice', title: 'Reading Comprehension Strategies', slug: 'spanish-reading-practice', category: 'practical-skills', content: '', order: 2 },
      { id: 'writing-skills', title: 'Writing Practice and Common Mistakes', slug: 'spanish-writing-skills', category: 'practical-skills', content: '', order: 3 },
      { id: 'study-tips', title: 'Study Strategies and Learning Resources', slug: 'spanish-study-tips', category: 'practical-skills', content: '', order: 4 }
    ]
  },
  {
    id: 'culture-etiquette',
    title: 'Spanish Culture and Etiquette',
    lessons: [
      { id: 'cultural-norms', title: 'Cultural Norms in Spanish-Speaking Countries', slug: 'spanish-cultural-norms', category: 'culture-etiquette', content: '', order: 1 },
      { id: 'etiquette-tips', title: 'Etiquette Tips for Travelers', slug: 'spanish-etiquette-tips', category: 'culture-etiquette', content: '', order: 2 },
      { id: 'festivals-holidays', title: 'Festivals and Holidays in Spain and Latin America', slug: 'spanish-festivals-holidays', category: 'culture-etiquette', content: '', order: 3 },
      { id: 'customs-traditions', title: 'Understanding Spanish Customs and Traditions', slug: 'spanish-customs-traditions', category: 'culture-etiquette', content: '', order: 4 }
    ]
  },
  {
    id: 'advanced-conversation',
    title: 'Advanced Conversation Skills',
    lessons: [
      { id: 'idiomatic-expressions', title: 'Advanced Idiomatic Expressions', slug: 'spanish-idiomatic-expressions', category: 'advanced-conversation', content: '', order: 1 },
      { id: 'colloquial-spanish', title: 'Colloquial Spanish and Slang', slug: 'spanish-colloquial', category: 'advanced-conversation', content: '', order: 2 },
      { id: 'discussion-debate', title: 'Discussion and Debate Skills', slug: 'spanish-discussion-debate', category: 'advanced-conversation', content: '', order: 3 },
      { id: 'advanced-vocabulary', title: 'Specialized and Academic Vocabulary', slug: 'spanish-advanced-vocabulary', category: 'advanced-conversation', content: '', order: 4 }
    ]
  }
];