/* src\components\Navigation.tsx */

import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AccentContext } from '../AccentContext';
import { MessageCircle, Menu } from 'lucide-react';
import MobileMenu from './MobileMenu';
import { Category } from '../types';

interface NavigationProps {
  currentCategory?: Category | null;
}

const Navigation: React.FC<NavigationProps> = ({ currentCategory }) => {
  const { accent, setAccent } = useContext(AccentContext);
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Active link styles
  const getLinkStyles = (path: string) => {
    const isActive = location.pathname === path;
    return `text-sm font-medium transition-colors ${
      isActive ? 'text-gray-900' : 'text-gray-600 hover:text-gray-900'
    }`;
  };

  return (
    <header className="sticky top-0 z-50 w-full bg-white border-b border-gray-100 shadow-sm">
      <div className="container max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
          <Link 
            to="/" 
            className="flex items-center gap-2 focus:outline-none focus:ring-2 
                     focus:ring-violet-500 focus:ring-offset-2 rounded-md
                     hover:opacity-90 transition-opacity"
          >
            <div className="flex items-center justify-center w-8 h-8 rounded-lg 
                          bg-violet-50">
              <MessageCircle className="w-5 h-5 text-violet-600" />
            </div>
            <span className="text-xl font-bold bg-gradient-to-r from-violet-600 
                           to-indigo-600 bg-clip-text text-transparent">
              LearnSpanish.org
            </span>
          </Link>

          <div className="hidden md:flex items-center space-x-8">
            {/* Accent selector temporarily commented out
            <div className="flex items-center space-x-2">
              <label 
                htmlFor="accentSelect" 
                className="text-sm font-medium text-gray-600"
              >
                Accent:
              </label>
              <select
                id="accentSelect"
                aria-label="Select accent variant"
                value={accent}
                onChange={(e) => handleAccentChange(e.target.value as 'latam' | 'castilian')}
                className="text-sm border border-gray-200 rounded-lg px-3 py-1.5 
                         bg-white focus:outline-none focus:ring-2 focus:ring-violet-500/20 
                         focus:border-violet-500 transition-colors"
              >
                <option value="latam">Latin American</option>
                <option value="castilian">Castilian</option>
              </select>
            </div>
            */}

            <nav className="flex items-center space-x-6">
              <Link 
                to="/" 
                className={getLinkStyles('/')}
              >
                Home
              </Link>
            </nav>
          </div>

          {/* Mobile menu button - hidden on desktop */}
          <button 
            onClick={() => setIsMobileMenuOpen(true)}
            className="md:hidden inline-flex items-center justify-center p-2 rounded-lg 
                     text-gray-500 hover:text-gray-900 hover:bg-gray-50 
                     focus:outline-none focus:ring-2 focus:ring-violet-500/20"
          >
            <span className="sr-only">Open main menu</span>
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        currentCategory={currentCategory}
      />
    </header>
  );
};

export default Navigation;