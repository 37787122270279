import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from '../../types';

interface LessonSidebarProps {
  category: Category | null;
  currentSlug: string;
}

const LessonSidebar: React.FC<LessonSidebarProps> = ({ category, currentSlug }) => {
  if (!category) return null;
  
  return (
    <aside className="hidden lg:block lg:w-80 mt-8 lg:mt-0">
      <div className="bg-white rounded-lg shadow-sm p-6 sticky top-24">
        <h2 className="text-lg font-semibold mb-4">In This Category</h2>
        <ul className="space-y-3">
          {category.lessons.map((lessonItem) => (
            <li key={lessonItem.id}>
              <Link
                to={`/lesson/${lessonItem.slug}`}
                className={`block text-sm ${
                  lessonItem.slug === currentSlug
                    ? 'text-violet-600 font-medium'
                    : 'text-gray-600 hover:text-violet-600'
                }`}
              >
                {lessonItem.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default LessonSidebar;