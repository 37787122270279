import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { ElementRef, ComponentPropsWithoutRef } from 'react';

const TooltipProvider = TooltipPrimitive.Provider

// `TooltipPrimitive.Root` doesn't require forwardRef since it doesn't forward refs internally
const Tooltip = (props: TooltipPrimitive.TooltipProps) => (
  <TooltipPrimitive.Root {...props} />
)
Tooltip.displayName = TooltipPrimitive.Root.displayName

const TooltipTrigger = React.forwardRef<
  ElementRef<typeof TooltipPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>((props, ref) => (
  <TooltipPrimitive.Trigger ref={ref} {...props} />
))
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName

const TooltipContent = React.forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & { className?: string }
>(({ className = "", children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={`tooltip-content ${className}`}
    sideOffset={5}
    {...props}
  >
    {/* Add the arrow back in */}
    <TooltipPrimitive.Arrow className="tooltip-arrow" />
    {children}
  </TooltipPrimitive.Content>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
