import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';

interface NoResultsProps {
  searchTerm: string;
}

const NoResults: React.FC<NoResultsProps> = ({ searchTerm }) => {
  return (
    <div className="py-12 text-center">
      <p className="text-gray-600 mb-4">
        {searchTerm
          ? 'No results found. Try different keywords or browse our lessons below.'
          : 'Enter a search term to find Spanish lessons and resources.'}
      </p>
      <Link
        to="/"
        className="inline-flex items-center text-violet-600 hover:text-violet-700"
      >
        <Home className="w-4 h-4 mr-2" />
        Browse All Lessons
      </Link>
    </div>
  );
};

export default NoResults;
