import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AccentContext } from './AccentContext';
import { SearchProvider } from './contexts/SearchContext';
import { TooltipProvider } from '@radix-ui/react-tooltip'; 
import Layout from './components/Layout';
import LessonPage from './pages/LessonPage';
import SearchResults from './pages/SearchResults';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function getAccentFromCookie(): 'latam' | 'castilian' {
  const match = document.cookie.match(/(^| )accent=([^;]+)/);
  if (match && (match[2] === 'latam' || match[2] === 'castilian')) {
    return match[2] as 'latam' | 'castilian';
  }
  return 'latam';
}

const App: React.FC = () => {
  const [accent, setAccent] = useState<'latam' | 'castilian'>(getAccentFromCookie());
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    document.cookie = `accent=${accent};path=/;max-age=${365 * 24 * 60 * 60}`;
  }, [accent]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoaded(true);
    });
  }, []);

  if (!fontsLoaded) {
    return <div className="min-h-screen bg-white" />; // Loading state
  }

  return (
    <HelmetProvider>
      <AccentContext.Provider value={{ accent, setAccent }}>
        <SearchProvider>
          <TooltipProvider delayDuration={0}>
            <Router>
              <Layout>
                <Routes>
                  <Route path="/lesson/:slug" element={<LessonPage />} />
                  <Route path="/search" element={<SearchResults />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Layout>
            </Router>
          </TooltipProvider>
        </SearchProvider>
      </AccentContext.Provider>
    </HelmetProvider>
  );
};

export default App;