import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Terms: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>Terms of Use - LearnSpanish.org</title>
        <meta 
          name="description" 
          content="Terms and conditions for using LearnSpanish.org's free Spanish learning platform. Learn about your rights and responsibilities while using our educational content."
        />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        {/* Header Section */}
        <div className="bg-white border-b border-gray-200">
          <div className="max-w-4xl mx-auto px-4 py-12">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Terms of Use</h1>
            <p className="text-gray-600">Last Updated: January {currentYear}</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="bg-white rounded-lg shadow-sm p-8">
            <div className="prose max-w-none">
              {/* Introduction */}
              <p>
                Welcome to LearnSpanish.org. By accessing this website, you accept and agree 
                to be bound by these Terms of Use. Please read them carefully before using our site.
              </p>

              {/* Agreement to Terms */}
              <h2>1. Agreement to Terms</h2>
              <p>
                By accessing or using LearnSpanish.org ("the Website"), you agree to be bound 
                by these Terms of Use. If you disagree with any part of these terms, you may 
                not access the Website.
              </p>

              {/* Intellectual Property */}
              <h2>2. Intellectual Property Rights</h2>
              <p>
                All content on this Website, including but not limited to text, graphics, 
                logos, images, audio clips, digital downloads, and data compilations, is 
                the property of LearnSpanish.org or its content suppliers and is protected 
                by international copyright laws.
              </p>

              <h3>2.1 Educational Content Usage</h3>
              <p>
                Our educational content is provided for personal, non-commercial use only. 
                You may not:
              </p>
              <ul>
                <li>Modify or copy the materials</li>
                <li>Use the materials for any commercial purpose</li>
                <li>Attempt to decompile or reverse engineer any software contained on the Website</li>
                <li>Remove any copyright or other proprietary notations from the materials</li>
                <li>Transfer the materials to another person or "mirror" the materials on any other server</li>
              </ul>

              {/* Website Usage */}
              <h2>3. Website Usage</h2>
              <h3>3.1 Permitted Use</h3>
              <p>
                You may access and use our educational content for personal learning purposes. 
                This includes viewing lessons, practicing exercises, and using learning resources 
                provided on the Website.
              </p>

              <h3>3.2 Prohibited Activities</h3>
              <p>You agree not to:</p>
              <ul>
                <li>Use the Website in any way that could damage, disable, or impair the site</li>
                <li>Attempt to gain unauthorized access to any part of the Website</li>
                <li>Use any automated means to access or collect data from the Website</li>
                <li>Interfere with other users' access to the Website</li>
                <li>Upload or transmit viruses or other malicious code</li>
              </ul>

              {/* Advertising */}
              <h2>4. Advertising and Third-Party Content</h2>
              <p>
                The Website includes advertising content provided by third parties, including 
                Google AdSense. We are not responsible for the content of any third-party 
                advertisements. By using the Website, you acknowledge and agree that:
              </p>
              <ul>
                <li>You may be shown third-party advertisements</li>
                <li>These advertisements may be targeted based on your browsing behavior</li>
                <li>We do not endorse products or services advertised by third parties</li>
                <li>Your interactions with advertisers are solely between you and the advertiser</li>
              </ul>

              {/* Disclaimers */}
              <h2>5. Disclaimers and Limitations of Liability</h2>
              <p>
                The Website and its content are provided "as is" and "as available" without 
                any warranties, express or implied. LearnSpanish.org does not warrant that:
              </p>
              <ul>
                <li>The Website will be error-free or uninterrupted</li>
                <li>Defects will be corrected</li>
                <li>The Website is free of viruses or other harmful components</li>
                <li>The accuracy or completeness of any educational content</li>
              </ul>

              <p>
                In no event shall LearnSpanish.org be liable for any damages arising out of 
                the use or inability to use the Website or its content.
              </p>

              {/* Privacy Policy Reference */}
              <h2>6. Privacy Policy</h2>
              <p>
                Your use of the Website is also governed by our{' '}
                <Link to="/privacy" className="text-violet-600 hover:text-violet-700">
                  Privacy Policy
                </Link>. Please review this policy to understand our practices.
              </p>

              {/* Modifications */}
              <h2>7. Modifications to Terms and Website</h2>
              <p>
                We reserve the right to modify these Terms at any time. Changes will be 
                effective immediately upon posting to the Website. Your continued use of 
                the Website after changes constitutes acceptance of the modified Terms.
              </p>

              <p>
                We may also modify, suspend, or discontinue any part of the Website without 
                notice or liability.
              </p>

              {/* Governing Law */}
              <h2>8. Governing Law</h2>
              <p>
                These Terms shall be governed by and construed in accordance with the laws 
                of the United States, without regard to its conflict of law provisions.
              </p>

              {/* Contact Information */}
              <h2>9. Contact Information</h2>
              <p>
                If you have any questions about these Terms, please{' '}
                <Link to="/contact" className="text-violet-600 hover:text-violet-700">
                  contact us
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;