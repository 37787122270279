import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Category } from '../types';
import { X } from 'lucide-react';
import { cn } from '../lib/utils';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  currentCategory?: Category | null;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  currentCategory
}) => {
  const location = useLocation();
  const currentSlug = location.pathname.split('/').pop();

  return (
    <>
      {/* Backdrop */}
      <div 
        className={cn(
          "fixed inset-0 bg-black/20 backdrop-blur-sm z-40 transition-opacity",
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        )}
        onClick={onClose}
        aria-hidden="true"
      />

      {/* Menu Panel */}
      <div
        className={cn(
          "fixed inset-y-0 right-0 w-[280px] bg-white z-50",
          "transform transition-transform duration-200 ease-in-out overflow-hidden flex flex-col",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
        role="dialog"
        aria-modal="true"
        aria-label="Mobile menu"
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="font-semibold text-gray-900">
            {currentCategory ? 'In This Category' : 'Menu'}
          </h2>
          <button
            onClick={onClose}
            className="p-2 -m-2 text-gray-500 hover:text-gray-700 
                     transition-colors rounded-full"
            aria-label="Close menu"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Menu Content */}
        <div className="flex-1 overflow-y-auto">
          {currentCategory ? (
            // Category lessons menu
            <nav className="p-4">
              <Link
                to="/"
                onClick={onClose}
                className="block py-2.5 text-[15px] text-gray-600 mb-4 border-b pb-4"
              >
                ← Back to Home
              </Link>
              {currentCategory.lessons.map((lesson) => (
                <Link
                  key={lesson.id}
                  to={`/lesson/${lesson.slug}`}
                  onClick={onClose}
                  className={cn(
                    "block py-2.5 text-[15px]",
                    lesson.slug === currentSlug 
                      ? "text-violet-600 font-medium"
                      : "text-gray-600"
                  )}
                >
                  {lesson.title}
                </Link>
              ))}
            </nav>
          ) : (
            // Main navigation menu
            <nav className="p-4 space-y-1">
              <Link
                to="/"
                onClick={onClose}
                className={cn(
                  "block py-2.5 text-[15px]",
                  location.pathname === "/" 
                    ? "text-violet-600 font-medium"
                    : "text-gray-600"
                )}
              >
                Home
              </Link>
              <Link
                to="/#learning-path"
                onClick={onClose}
                className="block py-2.5 text-[15px] text-gray-600"
              >
                Learning Path
              </Link>
              <Link
                to="/about"
                onClick={onClose}
                className={cn(
                  "block py-2.5 text-[15px]",
                  location.pathname === "/about" 
                    ? "text-violet-600 font-medium"
                    : "text-gray-600"
                )}
              >
                About
              </Link>
              <Link
                to="/contact"
                onClick={onClose}
                className={cn(
                  "block py-2.5 text-[15px]",
                  location.pathname === "/contact" 
                    ? "text-violet-600 font-medium"
                    : "text-gray-600"
                )}
              >
                Contact
              </Link>
              <Link
                to="/faq"
                onClick={onClose}
                className={cn(
                  "block py-2.5 text-[15px]",
                  location.pathname === "/faq" 
                    ? "text-violet-600 font-medium"
                    : "text-gray-600"
                )}
              >
                FAQ
              </Link>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileMenu;