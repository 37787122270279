import React, { FormEvent, RefObject } from 'react';
import { Search, Filter } from 'lucide-react';

interface SearchFormProps {
  localSearchTerm: string;
  setLocalSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  searchInputRef: RefObject<HTMLInputElement>; // Note: No | null here
}

const SearchForm: React.FC<SearchFormProps> = ({
  localSearchTerm,
  setLocalSearchTerm,
  handleSubmit,
  searchInputRef
}) => {
  return (
    <div className="mb-8 relative" role="search">
      <form onSubmit={handleSubmit} className="relative">
        <div className="relative">
          <input
            ref={searchInputRef}
            type="search"
            placeholder='Search lessons (Press "/" to focus)'
            value={localSearchTerm}
            onChange={(e) => setLocalSearchTerm(e.target.value)}
            className="w-full pl-5 pr-12 py-4 rounded-xl border-2 border-violet-100 
                      bg-white shadow-sm
                      focus:border-violet-300 focus:ring-2 focus:ring-violet-200 
                      focus:outline-none transition-all
                      placeholder:text-gray-400 text-gray-900
                      sm:text-lg"
            aria-label="Search Spanish lessons"
            autoComplete="off"
          />
          <button
            type="submit"
            className="absolute right-4 top-1/2 transform -translate-y-1/2 
                      text-violet-600 hover:text-violet-700 transition-colors
                      focus:outline-none focus:ring-2 focus:ring-violet-500
                      disabled:opacity-50 p-2"
            disabled={!localSearchTerm.trim()}
            aria-label="Search"
          >
            <Search className="w-5 h-5" />
          </button>
        </div>
      </form>

      <div className="flex items-center gap-2 mt-2">
        <button
          className="inline-flex items-center px-3 py-1 rounded-full text-sm
                    bg-violet-50 text-violet-700 hover:bg-violet-100
                    transition-colors"
        >
          <Filter className="w-4 h-4 mr-1" />
          Beginner
        </button>
        <button className="px-3 py-1 rounded-full text-sm bg-violet-50 text-violet-700 hover:bg-violet-100">
          Intermediate
        </button>
        <button className="px-3 py-1 rounded-full text-sm bg-violet-50 text-violet-700 hover:bg-violet-100">
          Advanced
        </button>
      </div>
    </div>
  );
};

export default SearchForm;
