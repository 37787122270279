import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { loadLesson } from '../utils/lessonLoader';
import { ParsedLesson } from '../utils/lessonParser';
import { Category } from '../types';
import LessonContent from '../components/lesson/LessonContent';
import LessonSidebar from '../components/lesson/LessonSidebar';
import LessonBreadcrumbs from '../components/lesson/LessonBreadcrumbs';
import LessonNavigation from '../components/lesson/LessonNavigation';
import LessonAd from '../components/lesson/LessonAd';
import { AccentContext } from '../AccentContext';

interface LessonContext {
  prev: Category | null;
  next: Category | null;
  category: Category | null;
}

const LessonPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [lesson, setLesson] = useState<ParsedLesson | null>(null);
  const [context, setContext] = useState<LessonContext>({
    prev: null,
    next: null,
    category: null
  });
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { accent } = useContext(AccentContext);

  useEffect(() => {
    const fetchLesson = async () => {
      if (!slug) return;

      try {
        setIsLoading(true);
        setError(null);

        const { lesson: loadedLesson, context: loadedContext } = await loadLesson(slug);

        setLesson(loadedLesson);
        setContext(loadedContext);
      } catch (err) {
        console.error('Error loading lesson:', err);
        setError(err instanceof Error ? err.message : 'Failed to load lesson');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLesson();
  }, [slug]);

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded">
          {error}
        </div>
      </div>
    );
  }

  if (isLoading || !lesson) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            <div className="h-4 bg-gray-200 rounded w-4/5"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F9F8FF]">
      <div className="bg-violet-600 h-1 fixed top-0 left-0 w-1/3 z-50"></div>

      <div className="max-w-7xl mx-auto px-4 py-8 lg:flex lg:gap-8">
        <main className="lg:flex-grow lg:max-w-4xl">
          <article className="bg-white rounded-lg shadow-sm p-6 lg:p-8">
            <LessonBreadcrumbs
              category={context.category}
              title={lesson.meta.title}
            />

            <LessonContent content={lesson.content} accent={accent} />

            <LessonNavigation
              prevLesson={context.prev?.lessons[0] || null}
              nextLesson={context.next?.lessons[0] || null}
            />
          </article>

          {/* <LessonAd /> */}
        </main>

        <LessonSidebar
          category={context.category}
          currentSlug={slug || ''}
        />
      </div>
    </div>
  );
};

export default LessonPage;