import React, { useEffect, useState, useRef, FormEvent } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { useSearch } from '../contexts/SearchContext';
import SearchForm from './search/SearchForm';
import ResultsList from './search/ResultsList';
import Pagination from './search/Pagination';
import SearchPageMetadata from './search/SearchPageMetadata';
import Breadcrumbs from './search/Breadcrumbs';
import LoadingIndicator from './search/LoadingIndicator';
import NoResults from './search/NoResults';

const SearchResults: React.FC = () => {
  const {
    searchTerm,
    setSearchTerm,
    results,
    isSearching,
    currentPage,
    setCurrentPage,
    totalPages,
    performSearch,
    loadFromStorage
  } = useSearch();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  
  // Ensure no '| null' in the generic
  const searchInputRef = useRef<HTMLInputElement>(null);
  
  const [selectedResult, setSelectedResult] = useState(-1);

  // Hotkeys
  useHotkeys('up', (event: KeyboardEvent) => {
    event.preventDefault();
    setSelectedResult(prev => Math.max(prev - 1, 0));
  });

  useHotkeys('down', (event: KeyboardEvent) => {
    event.preventDefault();
    setSelectedResult(prev => Math.min(prev + 1, results.length - 1));
  });

  useHotkeys('enter', () => {
    if (selectedResult >= 0 && results[selectedResult]?.hasContent) {
      navigate(`/lesson/${results[selectedResult].slug}`);
    }
  });

  useHotkeys('/', (event: KeyboardEvent) => {
    event.preventDefault();
    searchInputRef.current?.focus();
  });

  useEffect(() => {
    const queryParam = searchParams.get('q') || '';
    const pageParam = parseInt(searchParams.get('page') || '1', 10);

    if (queryParam && queryParam !== searchTerm) {
      setSearchTerm(queryParam);
      const loaded = loadFromStorage(queryParam);
      if (!loaded) {
        performSearch(queryParam);
      }
    }

    if (pageParam !== currentPage) {
      setCurrentPage(pageParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchTerm) params.set('q', searchTerm);
    if (currentPage > 1) params.set('page', currentPage.toString());
    setSearchParams(params);
  }, [searchTerm, currentPage, setSearchParams]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const trimmedTerm = localSearchTerm.trim();
    if (!trimmedTerm) return;
    navigate(`/search?q=${encodeURIComponent(trimmedTerm)}`);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <SearchPageMetadata searchTerm={searchTerm} currentPage={currentPage} />

      <div className="min-h-screen bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 py-8">
          <Breadcrumbs />

          {/* Type assertion if needed */}
          <SearchForm
            localSearchTerm={localSearchTerm}
            setLocalSearchTerm={setLocalSearchTerm}
            handleSubmit={handleSubmit}
            searchInputRef={searchInputRef as React.RefObject<HTMLInputElement>}
          />

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h1 className="text-2xl font-bold mb-4">
              {searchTerm ? `Search Results for "${searchTerm}"` : 'Search Results'}
            </h1>

            {isSearching ? (
              <LoadingIndicator />
            ) : results.length > 0 ? (
              <>
                <ResultsList
                  results={results}
                  selectedResult={selectedResult}
                  setSelectedResult={setSelectedResult}
                  totalResults={results.length}
                  searchTerm={searchTerm}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </>
            ) : (
              <NoResults searchTerm={searchTerm} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
