import React from 'react';
import parse, {
  domToReact,
  Element as HtmlParserElement,
  DOMNode as HtmlParserDOMNode,
  HTMLReactParserOptions
} from 'html-react-parser';
import PronunciationGuide from '../common/PronunciationGuide';
import ExampleBox from '../common/ExampleBox';
import NoteBox from '../common/NoteBox';
import ScenarioBox from '../common/ScenarioBox';
import ScenarioCarousel from '../common/ScenarioCarousel';

interface LessonContentProps {
  content: string;
  accent: 'latam' | 'castilian';
}

interface ScenarioData {
  title: string;
  audioPath: string;
  lines: {
    speaker: string;
    spanish: string;
    english: string;
    lineNumber: number;
  }[];
}

function isElement(node: HtmlParserDOMNode): node is HtmlParserElement {
  return (node as HtmlParserElement).type === 'tag';
}

const LessonContent: React.FC<LessonContentProps> = ({ content, accent }) => {
  const scenarios: ScenarioData[] = [];

  const options: HTMLReactParserOptions = {
    replace: (domNode: HtmlParserDOMNode) => {
      if (isElement(domNode)) {
        const el = domNode;

        // pronunciation-guide
        if (el.tagName === 'pronunciation-guide') {
          const word = el.attribs['data-word'] || '';
          const pronunciation = el.attribs['data-pronunciation'] || '';
          return <PronunciationGuide word={word} pronunciation={pronunciation} />;
        }

        // example-box
        if (el.attribs?.class?.includes('example-box')) {
          const children = el.children as HtmlParserDOMNode[];
          const elementChildren = children.filter(isElement);
          const spanishLine = elementChildren.find(line => line.attribs?.class === 'spanish-line');
          const englishLine = elementChildren.find(line => line.attribs?.class === 'english-line');

          const spanishContent = spanishLine ? domToReact(spanishLine.children as HtmlParserDOMNode[], options) : null;
          const englishContent = englishLine ? domToReact(englishLine.children as HtmlParserDOMNode[], options) : null;

          const label = el.attribs['data-label'] || '';
          return <ExampleBox label={label} spanishContent={spanishContent} englishContent={englishContent} />;
        }

        // note-box
        if (el.attribs?.class?.includes('note-box')) {
          const label = el.attribs['data-label'] || '';
          const noteContent = el.attribs['data-content'] || '';
          return <NoteBox label={label} content={noteContent} />;
        }

        // scenario-box
        if (el.attribs?.class?.includes('scenario-box')) {
          const scenarioDataString = el.attribs['data-scenario'];
          if (scenarioDataString) {
            try {
              const scenarioData: ScenarioData = JSON.parse(
                scenarioDataString.replace(/&#39;/g, "'")
              );
              scenarios.push(scenarioData);
              return <span className="scenario-placeholder" />;
            } catch (err) {
              console.error('Error parsing scenario data:', err);
            }
          }
        }

        // translist-box
        if (el.attribs?.class?.includes('translist-box')) {
          return (
            <div className="not-prose">
              {domToReact(el.children as HtmlParserDOMNode[], options)}
            </div>
          );
        }
      }

      return undefined;
    }
  };

  const parsedContent = parse(content, options);

  let firstScenarioInserted = false;

  function transformTree(node: React.ReactNode): React.ReactNode {
    if (typeof node === 'string' || typeof node === 'number') {
      return node;
    }

    if (React.isValidElement(node)) {
      // Cast node to ReactElement with known props shape
      const element = node as React.ReactElement<{ className?: string; children?: React.ReactNode }>;
      const { className, children } = element.props;

      if (className && className.includes('scenario-placeholder')) {
        if (!firstScenarioInserted) {
          firstScenarioInserted = true;
          if (scenarios.length > 1) {
            return <ScenarioCarousel scenarios={scenarios} accent={accent} />;
          } else if (scenarios.length === 1) {
            const single = scenarios[0];
            return (
              <ScenarioBox
                title={single.title}
                audioPath={single.audioPath}
                lines={single.lines}
                accent={accent}
              />
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      }

      const transformedChildren = React.Children.map(children, (child) => transformTree(child));
      return React.cloneElement(element, { ...element.props }, transformedChildren);
    }

    return node;
  }

  let finalContent: React.ReactNode;
  if (Array.isArray(parsedContent)) {
    finalContent = parsedContent.map((child) => transformTree(child));
  } else {
    finalContent = transformTree(parsedContent);
  }

  return (
    <article className="prose lg:prose-lg max-w-none">
      {finalContent}
    </article>
  );
};

export default LessonContent;
