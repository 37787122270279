import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Privacy: React.FC = () => {
  // Get current year for the "Last Updated" date
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>Privacy Policy - LearnSpanish.org</title>
        <meta 
          name="description" 
          content="LearnSpanish.org's privacy policy explains how we collect, use, and protect your information while using our Spanish learning platform."
        />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        {/* Header Section */}
        <div className="bg-white border-b border-gray-200">
          <div className="max-w-4xl mx-auto px-4 py-12">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
            <p className="text-gray-600">Last Updated: January {currentYear}</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="bg-white rounded-lg shadow-sm p-8">
            <div className="prose max-w-none">
              {/* Introduction */}
              <p>
                At LearnSpanish.org, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, 
                and safeguard your information when you visit our website.
              </p>

              {/* Information We Collect */}
              <h2>Information We Collect</h2>
              <h3>Automatically Collected Information:</h3>
              <ul>
                <li>Browser type and version</li>
                <li>Operating system</li>
                <li>IP address</li>
                <li>Page access times and dates</li>
                <li>Pages viewed on our site</li>
                <li>Referring website addresses</li>
              </ul>

              {/* Cookies and Similar Technologies */}
              <h2>Cookies and Similar Technologies</h2>
              <p>
                We use cookies and similar tracking technologies to track activity on our website and store certain information. 
                Cookies are files with a small amount of data that may include an anonymous unique identifier.
              </p>

              <h3>Types of Cookies We Use:</h3>
              <ul>
                <li><strong>Essential Cookies:</strong> Required for basic site functionality</li>
                <li><strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website</li>
                <li><strong>Advertising Cookies:</strong> Used to deliver relevant advertisements and track ad campaign performance</li>
              </ul>

              {/* Google Analytics and Advertising */}
              <h2>Google Analytics and Advertising</h2>
              <p>
                We use Google Analytics to analyze the use of our website. Google Analytics gathers information about website 
                use by means of cookies. We also use Google AdSense to display advertisements on our website.
              </p>

              <h3>Google Services on Our Site:</h3>
              <ul>
                <li>Google Analytics for visitor behavior analysis</li>
                <li>Google AdSense for displaying targeted advertisements</li>
                <li>Google Ad Manager for advertising management</li>
              </ul>

              <p>
                You can opt out of Google Analytics by installing the{' '}
                <a 
                  href="https://tools.google.com/dlpage/gaoptout" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-violet-600 hover:text-violet-700"
                >
                  Google Analytics Opt-out Browser Add-on
                </a>.
              </p>

              {/* How We Use Your Information */}
              <h2>How We Use Your Information</h2>
              <p>We use the collected information for various purposes:</p>
              <ul>
                <li>To provide and maintain our service</li>
                <li>To analyze and improve our website</li>
                <li>To display relevant, personalized advertisements</li>
                <li>To detect, prevent, and address technical issues</li>
                <li>To monitor the usage of our service</li>
              </ul>

              {/* Data Retention */}
              <h2>Data Retention</h2>
              <p>
                We retain logged information for a reasonable period of time. Analytics data is retained according to Google Analytics' 
                data retention policies.
              </p>

              {/* Your Rights */}
              <h2>Your Rights</h2>
              <p>You have the right to:</p>
              <ul>
                <li>Opt out of cookies through your browser settings</li>
                <li>Opt out of Google Analytics tracking</li>
                <li>Request information about what data we have collected about you</li>
                <li>Request deletion of your data where applicable</li>
              </ul>

              {/* Children's Privacy */}
              <h2>Children's Privacy</h2>
              <p>
                Our website is not intended for children under 13 years of age. We do not knowingly collect personal 
                information from children under 13.
              </p>

              {/* Security */}
              <h2>Security</h2>
              <p>
                We implement appropriate technical and organizational security measures to protect your information. 
                However, no method of transmission over the Internet is 100% secure.
              </p>

              {/* Changes to Privacy Policy */}
              <h2>Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new 
                Privacy Policy on this page and updating the "Last Updated" date.
              </p>

              {/* Contact Information */}
              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please{' '}
                <Link to="/contact" className="text-violet-600 hover:text-violet-700">
                  contact us
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;