/* src/components/common/PronunciationGuide.tsx */

import React, { useState } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '../ui/tooltip';

interface PronunciationGuideProps {
  word: string;
  pronunciation: string;
  className?: string;
}

const PronunciationGuide: React.FC<PronunciationGuideProps> = ({
  word,
  pronunciation,
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!word || !pronunciation) {
    return <span className={className}>{word || ''}</span>;
  }

  return (
    <TooltipProvider>
      <Tooltip defaultOpen={false} open={isOpen} onOpenChange={setIsOpen}>
        <TooltipTrigger asChild>
          <span
            className={`cursor-help border-b border-dotted border-violet-300 hover:border-violet-500 transition-colors active:border-violet-500 ${className}`}
            onClick={() => setIsOpen(prev => !prev)}
          >
            <em className="text-violet-600 font-medium">{word}</em>
          </span>
        </TooltipTrigger>
        <TooltipContent
          className="z-50 min-w-max bg-white px-2 py-1 text-sm font-medium border rounded shadow-md"
          onPointerDownOutside={() => setIsOpen(false)}
        >
          <span className="font-mono text-violet-600">{pronunciation}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default PronunciationGuide;