// src/components/lesson/LessonNavigation.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Lesson } from '../../types';

interface LessonNavigationProps {
  prevLesson: Lesson | null;
  nextLesson: Lesson | null;
}

const LessonNavigation: React.FC<LessonNavigationProps> = ({ prevLesson, nextLesson }) => {
  return (
    <nav className="mt-12 pt-8 border-t border-gray-200">
      <div className="flex justify-between items-center">
        {prevLesson ? (
          <Link
            to={`/lesson/${prevLesson.slug}`}
            className="inline-flex items-center text-violet-600 hover:text-violet-700"
          >
            <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Previous Lesson
          </Link>
        ) : (
          <div></div>
        )}

        {nextLesson && (
          <Link
            to={`/lesson/${nextLesson.slug}`}
            className="inline-flex items-center text-violet-600 hover:text-violet-700"
          >
            Next Lesson
            <svg className="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default LessonNavigation;