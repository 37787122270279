import React from 'react';
import { Helmet } from 'react-helmet-async';

const FAQ: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions - LearnSpanish.org</title>
        <meta
          name="description"
          content="Find answers to common questions about learning Spanish with LearnSpanish.org. Learn about our free courses, learning methods, and more."
        />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        <div className="bg-violet-600 text-white">
          <div className="max-w-4xl mx-auto px-4 py-16 text-center">
            <h1 className="text-4xl font-bold mb-4">Frequently Asked Questions</h1>
            <p className="text-xl text-violet-100">
              Find answers to common questions about learning Spanish with us
            </p>
          </div>
        </div>

        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="space-y-8">
            {/* General Questions */}
            <section>
              <h2 className="text-2xl font-bold mb-6">General Questions</h2>
              <div className="space-y-6">
                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Is LearnSpanish.org really free?
                  </h3>
                  <p className="text-gray-600">
                    Yes! All our lessons and learning materials are completely free. We believe everyone should have access to quality Spanish education without cost barriers.
                  </p>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Do I need to create an account?
                  </h3>
                  <p className="text-gray-600">
                    No account is required to access our lessons and learning materials. You can start learning Spanish right away!
                  </p>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Which dialect of Spanish do you teach?
                  </h3>
                  <p className="text-gray-600">
                    Our lessons cover both Latin American and Castilian (European) Spanish, highlighting important regional differences when relevant. This helps learners understand and communicate effectively across Spanish-speaking regions.
                  </p>
                </div>
              </div>
            </section>

            {/* Learning Process */}
            <section>
              <h2 className="text-2xl font-bold mb-6">Learning Process</h2>
              <div className="space-y-6">
                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    How should I start learning?
                  </h3>
                  <p className="text-gray-600">
                    We recommend starting with our beginner lessons, which cover essential topics like pronunciation, basic vocabulary, and grammar fundamentals. Follow our structured learning path for the best results.
                  </p>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    How long does it take to learn Spanish?
                  </h3>
                  <p className="text-gray-600">
                    Learning speed varies by individual, but with regular practice (30-60 minutes daily), most learners can achieve basic conversational fluency within 6-8 months. Mastering the language takes longer and depends on your goals and dedication.
                  </p>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    How often should I practice?
                  </h3>
                  <p className="text-gray-600">
                    Regular, consistent practice is key. We recommend studying at least 30 minutes daily rather than longer, irregular sessions. This helps maintain momentum and improves retention.
                  </p>
                </div>
              </div>
            </section>

            {/* Technical Questions */}
            <section>
              <h2 className="text-2xl font-bold mb-6">Technical Questions</h2>
              <div className="space-y-6">
                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Can I access the lessons on mobile devices?
                  </h3>
                  <p className="text-gray-600">
                    Yes! Our website is fully responsive and works well on smartphones and tablets. You can learn Spanish anywhere, anytime.
                  </p>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    How can I report a technical issue?
                  </h3>
                  <p className="text-gray-600">
                    If you encounter any technical issues, please visit our Contact page and describe the problem. Our team will investigate and respond as quickly as possible.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;