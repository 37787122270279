import React from 'react';
import { Link } from 'react-router-dom';

interface ResultItemProps {
  result: {
    title: string;
    description?: string;
    slug: string;
    category: string;
    hasContent: boolean;
  };
  isSelected: boolean;
  index: number;
  onMouseEnter: (index: number) => void;
}

const ResultItem: React.FC<ResultItemProps> = ({
  result,
  isSelected,
  index,
  onMouseEnter
}) => {
  return (
    <div
      className={`py-4 ${isSelected ? 'bg-violet-50' : ''}`}
      onMouseEnter={() => onMouseEnter(index)}
    >
      {result.hasContent ? (
        <Link
          to={`/lesson/${result.slug}`}
          className="block group focus:outline-none focus:ring-2 focus:ring-violet-500 rounded-lg p-2"
        >
          <h2 className="text-xl font-semibold text-gray-900 group-hover:text-violet-600 transition-colors">
            {result.title}
          </h2>
          <p className="text-gray-600 mt-1 line-clamp-2">
            {result.description || 'Learn more about this Spanish lesson...'}
          </p>
          <div className="text-sm text-gray-500 mt-2">
            Category: {result.category}
          </div>
        </Link>
      ) : (
        <div className="opacity-75 p-2">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
            {result.title}
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              Coming Soon
            </span>
          </h2>
          <p className="text-gray-600 mt-1">
            This lesson is currently being developed and will be available soon.
          </p>
          <div className="text-sm text-gray-500 mt-2">
            Category: {result.category}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultItem;
