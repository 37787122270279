import React from 'react';
import parse from 'html-react-parser';
import { marked } from 'marked';
import { Info } from 'lucide-react';

interface NoteBoxProps {
  label?: string;
  content: string;
}

const NoteBox: React.FC<NoteBoxProps> = ({ label, content }) => {
  if (!content) {
    return null;
  }

  const renderedContent = parse(marked.parse(content, { async: false }));

  return (
    <div className="my-1 px-3 py-0.5 bg-gray-50 border border-gray-100 rounded-lg max-w-lg">
      <div className="flex items-center space-x-2">
        <Info className="text-gray-600 w-4 h-4" />
        <div className="text-gray-700 text-sm leading-relaxed">
          {label && (
            <span className="font-semibold mr-1">
              {label}:
            </span>
          )}
          {renderedContent}
        </div>
      </div>
    </div>
  );
};

export default NoteBox;
