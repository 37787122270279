import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { Category } from '../types';

interface LayoutProps {
  children: React.ReactNode;
  currentCategory?: Category | null;
}

const Layout: React.FC<LayoutProps> = ({ children, currentCategory }) => {
  return (
    <div className="flex flex-col min-h-screen w-full">
      <Navigation currentCategory={currentCategory} />
      <main className="flex-grow w-full">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;