import React, { useRef, useState } from 'react';
import { Volume2 } from 'lucide-react';

interface ScenarioLineProps {
  speaker: string;
  spanish: string;
  english: string;
  isOtherSpeaker: boolean;
  audioSrc: string;
  accent: 'latam' | 'castilian';
}

const ScenarioLine: React.FC<ScenarioLineProps> = ({
  speaker,
  spanish,
  english,
  isOtherSpeaker,
  audioSrc
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [showTranslation, setShowTranslation] = useState(false);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const isYou = !isOtherSpeaker;
  const bubbleBg = isYou ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black';
  const bubbleRounded = isYou
    ? 'rounded-tl-2xl rounded-br-2xl rounded-bl-2xl'
    : 'rounded-tr-2xl rounded-bl-2xl rounded-br-2xl';

  const displayedText = showTranslation && english ? english : spanish;
  const toggleLabel = showTranslation ? 'Hide translation' : 'Show translation';

  return (
    <div className={`flex flex-col ${isYou ? 'items-end' : 'items-start'} px-4 py-2 relative`}>
      <div className="flex items-center space-x-2 mb-1">
        <span className="text-xs font-semibold text-gray-600">{speaker}</span>
      </div>

      <div className={`relative ${bubbleBg} ${bubbleRounded} shadow-sm p-3 pr-12 max-w-xs z-0`}>
        <p className="text-base">{displayedText}</p>
        {isOtherSpeaker && audioSrc && (
          <button
            onClick={playAudio}
            aria-label="Play audio"
            className="absolute z-10 bg-transparent focus:outline-none
                       text-gray-700 transition-all 
                       hover:text-gray-900 active:text-gray-950 
                       hover:scale-110"
            style={{
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          >
            <Volume2 className="w-[14px] h-[14px]" />
          </button>
        )}
      </div>

      {english && (
        <button
          onClick={() => setShowTranslation(!showTranslation)}
          className="mt-1 text-xs font-semibold text-blue-600 hover:text-blue-800 focus:outline-none"
          aria-label={toggleLabel}
          style={{ textDecoration: 'none' }}
        >
          {toggleLabel}
        </button>
      )}

      {isOtherSpeaker && audioSrc && <audio ref={audioRef} src={audioSrc} preload="metadata"></audio>}
    </div>
  );
};

export default ScenarioLine;
