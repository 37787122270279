import React from 'react';
import { Layers, Check, Book, ArrowUp } from 'lucide-react';

const WhyLearnSection = () => {
  return (
    <section className="relative py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Why Learn with Us?
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Our proven method combines structured learning with practical application, 
            helping you achieve fluency faster while having fun.
          </p>
        </div>

        {/* Feature Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 auto-rows-fr mb-16">
          {/* Step-by-Step Card */}
          <div className="relative bg-white rounded-2xl p-6 ring-1 ring-gray-200 shadow-sm hover:shadow-xl transition duration-300 hover:-translate-y-1">
            <div className="flex flex-col items-center text-center h-full">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-[#409EFF]">
                <Layers className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mt-4 mb-2">
                Step-by-Step
              </h3>
              <p className="text-gray-500 text-sm leading-relaxed">
                Clear learning path from beginner to advanced
              </p>
            </div>
          </div>

          {/* Free Forever Card */}
          <div className="relative bg-white rounded-2xl p-6 ring-1 ring-gray-200 shadow-sm hover:shadow-xl transition duration-300 hover:-translate-y-1">
            <div className="flex flex-col items-center text-center h-full">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-[#8B5CF6]">
                <Check className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mt-4 mb-2">
                Free Forever
              </h3>
              <p className="text-gray-500 text-sm leading-relaxed">
                No hidden fees or premium content
              </p>
            </div>
          </div>

          {/* Practical Focus Card */}
          <div className="relative bg-white rounded-2xl p-6 ring-1 ring-gray-200 shadow-sm hover:shadow-xl transition duration-300 hover:-translate-y-1">
            <div className="flex flex-col items-center text-center h-full">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-[#EC4899]">
                <Book className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mt-4 mb-2">
                Practical Focus
              </h3>
              <p className="text-gray-500 text-sm leading-relaxed">
                Learn Spanish you'll actually use
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h3 className="text-2xl font-bold text-gray-900 mb-6">
            Ready to Start Your Spanish Journey?
          </h3>
          <a
            href="#learning-path"
            className="inline-flex items-center gap-2 px-6 py-3 
                     bg-gradient-to-r from-violet-600 to-[rgb(79,69,228)] text-white rounded-xl 
                     hover:opacity-90 active:opacity-95
                     shadow-sm hover:shadow transition-all
                     focus:outline-none focus:ring-2 focus:ring-violet-500/20
                     group"
          >
            Get Started
            <ArrowUp 
              className="w-4 h-4 transition-transform group-hover:-translate-y-1" 
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default WhyLearnSection;