import React from 'react';
import ScenarioLine from './ScenarioLine';

interface ScenarioLineData {
  speaker: string;
  spanish: string;
  english: string;
  lineNumber: number;
}

interface ScenarioBoxProps {
  title: string;
  audioPath: string;
  lines: ScenarioLineData[];
  accent: 'latam' | 'castilian';
}

const ScenarioBox: React.FC<ScenarioBoxProps> = ({ title, audioPath, lines, accent }) => {
  return (
    <div
      className="my-8 mx-auto bg-white border border-gray-300 shadow-sm 
                 rounded-3xl max-w-[360px] w-full overflow-hidden 
                 relative flex flex-col transition-opacity duration-300 opacity-100"
    >
      <div className="w-full h-6 bg-gray-100 flex items-center justify-center relative">
        <div className="w-8 h-1.5 bg-gray-400 rounded-full" />
      </div>

      <div className="px-6 py-4 border-b border-gray-100">
        <h3 className="text-xl font-semibold text-gray-900 mb-2 sm:mb-0">
          {title}
        </h3>
      </div>

      <div className="p-6 space-y-4">
        {lines.map((line, index) => {
          const isOtherSpeaker = !line.speaker.toLowerCase().includes('you');
          const audioSrc = isOtherSpeaker
            ? `${audioPath}/line${line.lineNumber}-${accent}.mp3`
            : '';

          return (
            <ScenarioLine
              key={index}
              speaker={line.speaker}
              spanish={line.spanish}
              english={line.english}
              isOtherSpeaker={isOtherSpeaker}
              audioSrc={audioSrc}
              accent={accent}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ScenarioBox;
