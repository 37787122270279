import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* About Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <span className="text-xl font-bold bg-gradient-to-r from-violet-600 
                           to-indigo-600 bg-clip-text text-transparent">
                LearnSpanish.org
              </span>
            </div>
            <p className="text-gray-600 text-sm leading-relaxed">
              Your comprehensive resource for learning Spanish online, 
              featuring structured lessons from beginner to advanced levels.
            </p>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="font-semibold text-gray-900">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link 
                  to="/about" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div className="space-y-4">
            <h3 className="font-semibold text-gray-900">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/search" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Search Lessons
                </Link>
              </li>
              <li>
                <Link 
                  to="/#learning-path" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  Learning Path
                </Link>
              </li>
              <li>
                <Link 
                  to="/faq" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div className="space-y-4">
            <h3 className="font-semibold text-gray-900">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/privacy" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/terms" 
                  className="text-gray-600 hover:text-violet-600 text-sm transition-colors"
                >
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-12 pt-8 border-t border-gray-100">
          <p className="text-center text-sm text-gray-500">
            © {new Date().getFullYear()} LearnSpanish.org. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;