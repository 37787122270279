/* src\utils\lessonLoader.ts */

import { LessonParser, ParsedLesson } from './lessonParser';
import { BEGINNER_TOPICS, INTERMEDIATE_TOPICS, ADVANCED_TOPICS } from '../data/topics';
import { Category } from '../types';

export interface LessonContext {
  prev: Category | null;
  next: Category | null;
  category: Category | null;
}

export async function loadLesson(slug: string): Promise<{
  lesson: ParsedLesson;
  context: LessonContext;
}> {
  // Find the category and difficulty level
  const allTopics = [...BEGINNER_TOPICS, ...INTERMEDIATE_TOPICS, ...ADVANCED_TOPICS];
  const category = allTopics.find(cat => cat.lessons.some(l => l.slug === slug));

  if (!category) {
    throw new Error('Lesson not found');
  }

  let difficultyLevel = 'beginner';
  if (INTERMEDIATE_TOPICS.includes(category)) {
    difficultyLevel = 'intermediate';
  } else if (ADVANCED_TOPICS.includes(category)) {
    difficultyLevel = 'advanced';
  }

  try {
    // Load the markdown file
    const response = await fetch(`/content/lessons/${difficultyLevel}/${slug}.md`);
    if (!response.ok) {
      throw new Error(`Failed to load lesson: ${response.statusText}`);
    }

    const markdown = await response.text();
    const parsedLesson = await LessonParser.parse(markdown);
    const context = findLessonContext(slug, allTopics, difficultyLevel);

    return {
      lesson: parsedLesson,
      context
    };
  } catch (error) {
    console.error('Error loading lesson:', error);
    throw error;
  }
}

function findLessonContext(
  slug: string, 
  topics: Category[], 
  difficultyLevel: string
): LessonContext {
  // Get all lessons from current difficulty level
  const currentLevelTopics = difficultyLevel === 'beginner' 
    ? BEGINNER_TOPICS 
    : difficultyLevel === 'intermediate'
      ? INTERMEDIATE_TOPICS
      : [];

  // Flatten all lessons in current difficulty level
  const allLessonsInLevel = currentLevelTopics.flatMap(category => 
    category.lessons.map(lesson => ({
      ...lesson,
      category: category.title
    }))
  );

  // Find current lesson index
  const currentIndex = allLessonsInLevel.findIndex(l => l.slug === slug);
  if (currentIndex === -1) {
    return { prev: null, next: null, category: null };
  }

  // Find current category
  const currentCategory = topics.find(cat => 
    cat.lessons.some(l => l.slug === slug)
  ) || null;

  // Get prev and next lessons
  const prevLesson = currentIndex > 0 ? allLessonsInLevel[currentIndex - 1] : null;
  const nextLesson = currentIndex < allLessonsInLevel.length - 1 
    ? allLessonsInLevel[currentIndex + 1] 
    : null;

  // Find categories for prev and next lessons
  const prevCategory = prevLesson 
    ? topics.find(cat => cat.title === prevLesson.category) || null 
    : null;
  const nextCategory = nextLesson 
    ? topics.find(cat => cat.title === nextLesson.category) || null 
    : null;

  return {
    prev: prevLesson && prevCategory 
      ? { ...prevCategory, lessons: [prevLesson] } 
      : null,
    next: nextLesson && nextCategory 
      ? { ...nextCategory, lessons: [nextLesson] } 
      : null,
    category: currentCategory
  };
}